<template>
  <div>
    <index-navbar />
    <section
      class="header relative pt-16 items-center flex h-screen max-h-860-px"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-48 md:pt-32">
            <div class="w-full my-4 sm:pt-20 pt-48">
              <div class="flex flex-row">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="mr-3 bg-emerald-500 text-white active:bg-emerald-600 sm:text-xs md:text-xl font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i> +996 (709) 202 844
                </a>
                <a
                    href="tel:+996507744795"
                    target="_blank"
                    class="ml-3 bg-emerald-500 text-white active:bg-emerald-600 sm:text-xs md:text-xl font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i> +996 (507) 744 795
                </a>
              </div>
            </div>

            <h2 class="font-bold text-4xl text-blueGray-600">
              Услуги электрика в <span class="text-emerald-500">г. Бишкек</span>
            </h2>
            <p class="text-md leading-relaxed mt-4 text-blueGray-500">
              Мастерство в каждом соединении с нашими электриками!
            </p>
            <div class="bg-blueGray-800 shadow-xl rounded-lg py-5 px-12 relative z-10 mt-4" style="opacity: 65%">

              <div class="flex-auto pt-0">
                <form @submit.prevent="submit">
                  <h6 class="text-emerald-500 text-2xl font-semibold sm:px-0 md:px-4 mb-4">
                    Вызов мастера на дом
                  </h6>
                  <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 sm:px-0 md:px-4">
                      <div class="relative w-full mb-3">
                        <label
                            class="block text-white text-xs font-bold mb-1"
                        >
                          Ваше имя
                        </label>
                        <input
                            type="text"
                            class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Как к вам обращаться?"
                            v-model="name"
                        />
                      </div>
                    </div>
                    <div class="w-full lg:w-6/12 sm:px-0 md:px-4">
                      <div class="relative w-full mb-3">
                        <label
                            class="block text-white text-xs font-bold mb-1"
                        >
                          Номер для связи
                        </label>
                        <input
                            class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="+996(000)000-000"
                            v-maska data-maska="+996(###)###-###"
                            v-model="phoneNumber"
                            required
                        />
                      </div>
                    </div>
                    <div class="w-full lg:w-12/12 sm:px-0 md:px-4">
                      <div class="relative w-full mb-3">
                        <label
                            class="block text-white text-xs font-bold mb-1"
                        >
                          Примерный адрес или район
                        </label>
                        <input
                            type="text"
                            class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Например: Верхний Джал..."
                            v-model="address"
                        />
                      </div>
                    </div>
                    <div class="w-full lg:w-12/12 sm:px-0 md:px-4">
                      <div class="relative w-full mb-3">
                        <label
                            class="block text-white text-xs font-bold mb-1"
                        >
                          Описание работы
                        </label>
                        <textarea
                            type="text"
                            class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            rows="4"
                            placeholder="Пожалуйста опишите проблему. Например: Сгорела розетка, пропало электричество в доме и тд. ..."
                            v-model="description"
                        >
                        </textarea>
                      </div>
                    </div>
                    <div class="w-full flex justify-center mb-2 sm:px-0 md:px-4">
                      <VueRecaptcha
                          :sitekey="siteKey"
                          :load-recaptcha-script="true"
                          :language="lang"
                          @verify="handleSuccess"
                      ></VueRecaptcha>
                    </div>
                    <div class="w-full lg:w-12/12 sm:px-0 md:px-4">
                      <div class="relative w-full mb-3">
                        <button
                            class="mt-3 md:mt-0 text-white font-bold py-4 px-6 rounded outline-none focus:outline-none bg-emerald-500 active:bg-emerald-600 uppercase text-md shadow hover:shadow-lg ease-linear transition-all duration-150 w-full"
                            type="submit">
                          Вызвать мастера
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>

      <img
        class="absolute top-0 b-auto right-0 pt-16 w-12/12 max-h-860-px sm:block hidden"
        style="z-index: -999;"
        :src="mainImg"
        alt="..."
      />
    </section>

    <section class="pt-48 sm:pt-20 pb-40 relative bg-blueGray-100">

      <div class="justify-center text-center flex flex-wrap mt-24">
        <div class="w-full md:w-8/12 px-6 md:px-4">
          <h2 class="font-bold text-4xl uppercase">Наши услуги</h2>
          <p class="text-md leading-relaxed mt-4 mb-4 text-blueGray-500">
            Наша команда профессионалов готова приехать к вам в любое удобное время и выполнить качественный ремонт или обслуживание.
            Независимо от того, нужно ли вам установить новые электроприборы, исправить электрические неполадки или провести общий технический осмотр, мы с удовольствием поможем.
            Доверьтесь опыту и надежности наших специалистов – и ваш дом будет в надежных руках!
          </p>
        </div>
      </div>

      <div class="flex flex-wrap px-12 mx-4 justify-center">
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-6 text-center">
          <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-800"
          >
            <img
                alt="..."
                :src="services1"
                class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                    points="-30,95 583,95 583,65"
                    class="text-blueGray-800 fill-current"
                ></polygon>
              </svg>
              <h4 class="sm:text-md md:text-xl  font-bold text-white">
                Установка розеток
              </h4>
              <div class="flex flex-row justify-center pt-2">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i>
                </a>
                <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-6 text-center">
          <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-800"
          >
            <img
                alt="..."
                :src="services2"
                class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                    points="-30,95 583,95 583,65"
                    class="text-blueGray-800 fill-current"
                ></polygon>
              </svg>
              <h4 class="sm:text-sm md:text-xl  font-bold text-white">
                Монтаж электрического щита
              </h4>
              <div class="flex flex-row justify-center pt-2">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i>
                </a>
                <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-6 text-center">
          <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-800"
          >
            <img
                alt="..."
                :src="services3"
                class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                    points="-30,95 583,95 583,65"
                    class="text-blueGray-800 fill-current"
                ></polygon>
              </svg>
              <h4 class="sm:text-sm md:text-xl font-bold text-white">
                Монтаж электропроводки
              </h4>
              <div class="flex flex-row justify-center pt-2">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i>
                </a>
                <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-6 text-center">
          <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-800"
          >
            <img
                alt="..."
                :src="services4"
                class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                    points="-30,95 583,95 583,65"
                    class="text-blueGray-800 fill-current"
                ></polygon>
              </svg>
              <h4 class="sm:text-sm md:text-xl font-bold text-white">
                Установка люстр
              </h4>
              <div class="flex flex-row justify-center pt-2">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i>
                </a>
                <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-6 text-center">
          <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-800"
          >
            <img
                alt="..."
                :src="services5"
                class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                    points="-30,95 583,95 583,65"
                    class="text-blueGray-800 fill-current"
                ></polygon>
              </svg>
              <h4 class="sm:text-sm md:text-xl font-bold text-white">
                Электромонтаж под ключ
              </h4>
              <div class="flex flex-row justify-center pt-2">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i>
                </a>
                <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-6 text-center">
          <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-800"
          >
            <img
                alt="..."
                :src="services6"
                class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                    points="-30,95 583,95 583,65"
                    class="text-blueGray-800 fill-current"
                ></polygon>
              </svg>
              <h4 class="sm:text-sm md:text-xl font-bold text-white">
                Установка теплых полов
              </h4>
              <div class="flex flex-row justify-center pt-2">
                <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fa fa-phone-alt"></i>
                </a>
                <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </blockquote>
          </div>
        </div>

        <div class="sm:px-0 md:px-4 pt-8">
          <div class="relative w-full mb-3">
            <router-link to="/services">
              <button
                  class="mt-3 md:mt-0 text-white font-bold py-4 px-6 rounded outline-none focus:outline-none bg-emerald-500 active:bg-emerald-600 uppercase text-md shadow hover:shadow-lg ease-linear transition-all duration-150 w-full"
                  type="button">
                Посмотреть все услуги
                <i class="fa fa-arrow-circle-right ml-3"></i>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="relative py-20">

      <div class="container mx-auto px-4">
        <div class="flex flex-wrap text-center justify-center mb-5">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-bold text-emerald-500">
              Мы в социальных сетях!
            </h2>
          </div>
        </div>
        <div class="items-center flex flex-wrap">
          <div class="w-full ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__" data-unique-id="__svet_ake__" data-embed-from="embed_page" data-embed-type="creator" style="max-width: 780px; min-width: 288px;" >
              <section>
                <a target="_blank" href="https://www.tiktok.com/@__svet_ake__?refer=creator_embed">@__svet_ake__</a>
              </section>
            </blockquote>
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__/video/7328661436685667586" data-video-id="7328661436685667586" data-embed-from="embed_page" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@__svet_ake__" href="https://www.tiktok.com/@__svet_ake__?refer=embed">@__svet_ake__</a> <p>шит </p> <a target="_blank" title="♬ 原聲 - Pastry - yourongl" href="https://www.tiktok.com/music/原聲-Pastry-6927152827949239042?refer=embed">♬ 原聲 - Pastry - yourongl</a> </section> </blockquote>
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__/video/7328660302407552258" data-video-id="7328660302407552258" data-embed-from="embed_page" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@__svet_ake__" href="https://www.tiktok.com/@__svet_ake__?refer=embed">@__svet_ake__</a> <p></p> <a target="_blank" title="♬ FEEL THE GROOVE - Queens Road, Fabian Graetz" href="https://www.tiktok.com/music/FEEL-THE-GROOVE-6769046027488987137?refer=embed">♬ FEEL THE GROOVE - Queens Road, Fabian Graetz</a> </section> </blockquote>
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__/video/7328661436685667586" data-video-id="7328661436685667586" data-embed-from="embed_page" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@__svet_ake__" href="https://www.tiktok.com/@__svet_ake__?refer=embed">@__svet_ake__</a> <p>шит </p> <a target="_blank" title="♬ 原聲 - Pastry - yourongl" href="https://www.tiktok.com/music/原聲-Pastry-6927152827949239042?refer=embed">♬ 原聲 - Pastry - yourongl</a> </section> </blockquote>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-20 relative block bg-blueGray-800">
      <div class="container mx-auto px-4 lg:pt-24 lg:pb-64 pt-20 pb-32">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-bold text-white">
              Почему мы?
            </h2>
<!--            <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">-->
<!--              Наши электрики - это команда опытных специалистов, готовых решить любые задачи с электротехникой.-->
<!--              Мы ценим ваше доверие и стремимся превзойти ваши ожидания в каждом проекте.-->
<!--              Выбирая нас, вы выбираете надежного партнера для вашего дома или бизнеса.-->
<!--            </p>-->
          </div>
        </div>
        <div class="flex flex-wrap mt-12 justify-center">
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-medal text-xl"></i>
            </div>
            <h6 class="text-xl mt-5 font-semibold text-white">
              Профессионализм и опыт
            </h6>
            <p class="mt-2 mb-4 text-blueGray-400">
              Наши электрики обладают богатым опытом и профессиональными навыками,
              что позволяет им оперативно и качественно решать любые электротехнические задачи.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-lock text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Надежность и безопасность
            </h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              Мы придерживаемся строгих стандартов безопасности, гарантируя надежное и безопасное выполнение всех работ.
              Ваша безопасность – наш приоритет.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-check text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Гарантия качества
            </h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              Мы уверены в качестве наших услуг и предоставляем гарантию на выполненные работы.
              Вы можете быть уверены, что ваше электротехническое оборудование будет функционировать безупречно после нашего вмешательства.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-clock text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Быстрое реагирование
            </h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              Мы ценим ваше время и стремимся оперативно откликаться на ваши запросы.
              Наши электрики готовы приехать к вам в кратчайшие сроки и решить проблему эффективно.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
          <div class="w-full lg:w-6/12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
            >
              <div class="flex-auto p-5 lg:p-10">
                <h4 class="text-2xl font-semibold">
                  Хотите вызвать мастера на дом?
                </h4>
                <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                  Мы свяжемся с вами в ближайшее время
                </p>
                <div class="flex-auto pt-0">
                  <form @submit.prevent="submit">

                    <div class="flex flex-wrap">
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Ваше имя
                          </label>
                          <input
                              type="text"
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Как к вам обращаться?"
                              v-model="name"
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Номер для связи
                          </label>
                          <input
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="+996(000)000-000"
                              v-maska data-maska="+996(###)###-###"
                              v-model="phoneNumber"
                              required
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Примерный адрес или район
                          </label>
                          <input
                              type="text"
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Например: Верхний Джал..."
                              v-model="address"
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Описание работы
                          </label>
                          <textarea
                              type="text"
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              rows="4"
                              placeholder="Пожалуйста опишите проблему. Например: Сгорела розетка, пропало электричество в доме и тд. ..."
                              v-model="description"
                          >
                        </textarea>
                        </div>
                      </div>
                      <div class="w-full flex justify-center mb-2 sm:px-0 md:px-4">
                        <VueRecaptcha
                            :sitekey="siteKey"
                            :load-recaptcha-script="true"
                            :language="lang"
                            @verify="handleSuccess"
                        ></VueRecaptcha>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <button
                              class="mt-3 md:mt-0 text-white font-bold py-4 px-6 rounded outline-none focus:outline-none bg-emerald-500 active:bg-emerald-600 uppercase text-md shadow hover:shadow-lg ease-linear transition-all duration-150 w-full"
                              type="submit">
                            Вызвать мастера
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer-component />
  </div>
</template>
<script>
/* eslint-disable */
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import { vMaska } from "maska"
import {createToast} from "mosha-vue-toastify";
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha'

import services1 from "@/assets/img/services1.jpg";
import services2 from "@/assets/img/services3.jpg";
import services3 from "@/assets/img/services4.jpg";
import services4 from "@/assets/img/services5.jpg";
import services5 from "@/assets/img/services6.jpg";
import services6 from "@/assets/img/services7.jpeg";

import mainImg from "@/assets/img/header.jpeg";

export default {
  data() {
    return {
      mainImg,
      services1,
      services2,
      services3,
      services4,
      services5,
      services6,

      api: axios.create({
        baseURL: 'https://api.telegram.org/'
      }),

      name: '',
      phoneNumber: '',
      address: '',
      description: '',

      //Telegram BOT CONFIG
      token: '7173635452:AAEuvxXjP6HF3cHmSVA_pLbr8gbCVTpwc1k',
      chatId: -1002075741193,

      siteKey: '6LdVIIgpAAAAAJqF47XEwczCjMM51to2Vu1pBBxN',
      lang: 'ru',
      isVerified: false,
    };
  },
  directives: { maska: vMaska },
  components: {
    IndexNavbar,
    FooterComponent,
    VueRecaptcha,
  },
  methods: {
    handleSuccess() {
      this.isVerified = true
    },
    submit() {
      if(!this.isVerified){
        createToast({
          title: 'Пройдите проверку ReCapcha',
        }, {
          type: 'warning',
          position: 'top-right',
          showIcon: true,
        })
      } else {
        let fullMessage = 'Имя: ' + this.name + '\n'
            + 'Номер телефона: ' + this.phoneNumber + '\n'
            + 'Примерный адрес: ' + this.address + '\n'
            + 'Описание: ' + this.description + '\n'


        this.api.post(`bot${this.token}/sendMessage`, {}, {
          params: {
            chat_id: this.chatId,
            text: String(fullMessage),
          }
        }).then(
            response => {
              this.name = ''
              this.phoneNumber = ''
              this.address = ''
              this.description = ''
              this.isVerified = false
              createToast({
                title: 'Успешно!',
                description: 'Мы отправили ваш номер мастерам. В ближайшее время с вами свяжутся.',
              }, {
                type: 'success',
                position: 'top-right',
                showIcon: true,
              })
            },
            error => {
              createToast({
                title: 'Произошла ошибка!',
                description: 'Просим прощения. В ближайшее время мы исправим возникшую проблему.',
              }, {
                type: 'danger',
                position: 'top-right',
                showIcon: true,
              })
            }
        )
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
};
</script>
